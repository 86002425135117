import "core-js/modules/es.array.push.js";
import Tabbar from "@/components/tabbar";
import { listNavId } from "@/api";
export default {
  data() {
    return {
      imgs: "",
      list: [],
      title: "",
      percentage: 50
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (id < 5) {
      this.imgs = require(`@/assets/future/future_${id}.png`);
    } else {
      this.imgs = require(`@/assets/future/future_1.png`);
    }
    this.title = this.$route.query.title;
    listNavId(id).then(res => {
      this.list = res.data;
      this.title = res.data[0].class_name;
    });
  },
  methods: {
    goDeatiled(value) {
      this.$router.push({
        path: '/future-detailed',
        query: {
          id: value.id,
          title: value.title
        }
      });
    }
  },
  components: {
    Tabbar
  }
};